import React from 'react';
import styled from 'styled-components';
import Constants from '../Domain/constants';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';
import AppConfig from '../../src/Config';

class CVSSelector extends React.Component {
  state = {
    isWeb: true,
  };

  componentDidMount() {
    this._onWindowResize(); // initial call
    window.addEventListener( 'resize', this._onWindowResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onWindowResize, false)
  }

  render() {
    let { extraCss, store_id, store_name, onSelect } = this.props;
    let { isWeb } = this.state;

    return (
      <CVSSelector.Wrapper extraCss={extraCss} spStyle={AppConfig.favor === "tel25-sp"}>
        <form
          id="_cvs_chooser_form_"
          method="post"
          action={
            Constants.ECPAY_OP_MODE === 'Production'
              ? 'https://logistics.ecpay.com.tw/Express/map'
              : 'https://logistics-stage.ecpay.com.tw/Express/map'
          }
        >
          <input
            type="hidden"
            name="MerchantID"
            value={Constants.ECPAY_LOGISTICS_MERCHANT_ID}
          />
          <input type="hidden" name="LogisticsType" value="CVS" />
          <input type="hidden" name="LogisticsSubType" value="UNIMARTC2C" />
          <input type="hidden" name="IsCollection" value="Y" />
          <input type="hidden" name="Device" value={isWeb ? "0" : "1"} />

          <input
            type="hidden"
            name="ServerReplyURL"
            value={`${Constants.apiUrl}/api/ecpay_cvs_selector/result/?is_sp=${AppConfig.favor === 'tel25-sp' ? 'true' : 'false'}`}
          />
        </form>
        <div className="content" style={{display: 'flex', justifyContent: 'space-around'}}>
          <div>
            <p>{`${L.s(`store-number`)}${store_id || '---'}`}</p>
            <p>{`${L.s(`store-name`)}${store_name || '---'}`}</p>
          </div>
          <button
            onClick={() => {
              onSelect &&
                onSelect(document.getElementById('_cvs_chooser_form_'));
            }}
          >
            {store_id ? L.s(`reselect-store`) : L.s(`select-store`)}
          </button>
        </div>
      </CVSSelector.Wrapper>
    );
  }

  _onWindowResize = () => {
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if ((screenWidth > 740) !== this.state.isWeb) {
      this.setState({isWeb: screenWidth > 740});
    }
  };

  static Wrapper = styled.div`
    & > .content {
      display: flex;
      align-items: flex-start;

      & > button {
        flex-shrink: 0;
        margin-right: 20px;
        border-radius: 7px;
        border: 2px solid #3f8dcf;
        padding: 9px 20px;
        background-color: ${props => props.spStyle ? "#3f8dcf" : "#ffffff"};
        color: ${props => props.spStyle ? "#ffffff" : "#3f8dcf"};
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      & > div > p {
        margin-bottom: 10px;
        line-height: 30px;
      }

      & > div > p:last-child {
        margin-bottom: 0;
      }
    }

    ${props => props.extraCss || ''};
  `;
}

export default CVSSelector;

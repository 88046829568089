import React from 'react';
import { connect } from 'react-redux';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';
import CheckoutForm from '../Components/CheckoutForm';
import styled from 'styled-components';
import ShoppingList from '../Components/ShoppingList';
import * as Widget from '../Components/Widget';
import AppConfig from '../../src/Config';

class CheckoutPage extends React.Component {
  state = { disableNext: false, isCleaningCart: false};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let { profile, cartData, navActions } = this.props;
    let { disableNext, isCleaningCart } = this.state;
    let isEmpty = Object.keys(cartData.items).length === 0;

    if (!profile && AppConfig.favor === 'tel25') {
      return <Wrapper>{L.s(`please-login-first`)}</Wrapper>;
    }

    if (isEmpty) {
      if (isCleaningCart) {

        return (
          <Wrapper spStyle={AppConfig.favor === "tel25-sp"}>
            <Widget.Center>
              <Widget.Spinner />
            </Widget.Center>
          </Wrapper>
        )
      }
      return (
        <Wrapper spStyle={AppConfig.favor === "tel25-sp"}>
          <Widget.Center>
            <div className="hint">{L.s(`cart-empty`)}</div>
            {AppConfig.favor === "tel25-sp" && <button onClick={() => navActions.push("/")}>{L.s(`back-home`)}</button>}
          </Widget.Center>
        </Wrapper>
      );
    }

    return (
      <Wrapper spStyle={AppConfig.favor === "tel25-sp"}>
        {/*
         *  WORKAROUND:
         *  use <section/> element to prevent SSR render bug, since <Widget.Center/> is also a <div/>,
         *  and this will caused some style failed to applied.
         *  Actual cause is unknown.
         */}
        <section className="content">
          <h2 className="title">訂單資訊</h2>

          <ShoppingList
            extraCss="margin-bottom: 30px;"
            display={ShoppingList.DISPLAY_TYPE.CHECKOUT}
            data={cartData}
            disableNextCallback={disable =>
              this.setState({ disableNext: disable })
            }
          />

          <h2 className="title">寄送資訊</h2>

          <CheckoutForm setCleaningStatus={(status) => this.setState({isCleaningCart: status})}  disableNext={disableNext} />

        </section>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 30px 20px 50px;
  background-color: ${props => props.spStyle ? "#000" :"#f6f6f6"};

  ${props => {
    if (props.spStyle) {
      return 'background-image: radial-gradient(circle farthest-corner at 50% 50%, hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, .19));'
    } else {
      return ''
    }
  }}

  & button {
    margin-top: 10px;
    display: inline-block;
    margin-left: 15px;
    border-radius: 7px;
    border: none;
    padding: 8px 15px 7px;
    background: #3f8dcf;
    box-shadow: 0 2px 4px 0 rgba(0, 135, 241, 0.5);
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;

    :focus {
      outline: none;
    }
  }

  & .hint {
    ${props => {
      return props.spStyle ? 'color: #dbdbdb;' : ''
    }}
  }

  & > .content {
    max-width: 940px;
    margin: 0 auto;

    & > .title {
      margin-bottom: 10px;
      color: ${props => props.spStyle ? "#009eda" : "#3f8dcf"};
      font-size: ${props => props.spStyle ? 27 : 20}px;
      font-weight: ${props => props.spStyle ? 200 : 600};
      text-align: center;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.cart.getData(state),
    }),
    ActionCreator
  )(CheckoutPage)
);
